@use "../variables";
@use "../mixins";

.TableHead {
  position: relative;
  .clickable {
    cursor: pointer;
  }
  .title {
    display: flex;
    align-items: center;
    height: 35px;
    & span {
      text-align: center;
    }
    &.right {
      justify-content: right;
    }
    &.center {
      justify-content: center;
    }
  }
  .popup {
    position: fixed;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1.5rem;
    z-index: 2;
    box-shadow: .1rem 0 .5rem grey;
    .popup-element {
      display: flex;
      align-items: center;
    }
    .selected {
      color: variables.$color-primary;
    }

    @include mixins.up-to-lg {
      position: fixed;
      left: 2rem;
      width: calc(100vw - 6rem);
      max-width: 16rem;
      top: 5rem;
    }
  }
  .popup-closer-area {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}
