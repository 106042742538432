@use '../variables';
@use '../mixins';

.StatisticDiagram {
  padding-bottom: 5rem;
  form {
    & > * {
      padding-top: 1rem;
    }
    .upper-elements {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 2rem;
      .inputs {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        column-gap: 2rem;
        @include mixins.sm-up {
          flex-direction: row;
          align-items: flex-start;
        }
        .Dropdown {
          margin-bottom: 18px;
        }
        .DateTimeInput {
          padding-top: 0;
          margin-top: 10px;
        }
        .Button {
          margin-top: 24px;
        }
      }
      .graph-selection-section {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-bottom: 2rem;
        @include mixins.sm-up {
          flex-direction: row;
        }
        .graph-selection {
          display: flex;
          align-items: center;
          border-color: #003b5c;
          border-style: dashed;
          border-width: 2px;
          padding: .5rem;
          column-gap: 2rem;
          .Checkbox {
            font-size: 1rem;
          }
        }
      }
    }
    .line-chart {
      max-height: 65vh;
    }
    .no-dataset {
       display: flex;
       gap: .5rem;
     }
  }
}
