@use '../variables';
@use '../mixins';

.EditModal {
  max-height: 80vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  Img {
    width: 100%;
    height: auto;
    max-height: 30vh;
    object-fit: contain;
  }
  .modal-box {
    &.align-bottom {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
    .modal-box-element {
      padding-bottom: .5rem;
    }
  }
  .SymbolImage {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.5rem;
    width: 50px;
    height: 50px;
  }
}
