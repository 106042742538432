@use '../variables';

.DateTimeInput {

  font-size: .875rem;
  font-weight: 600;
  font-stretch: condensed;
  color: variables.$color-secondary;
  padding-top: 1rem;
  border-radius: 0;

  label {
    text-transform: uppercase;
  }

  &.required label:after {
    content: '*';
    color: variables.$color-danger;
    margin-left: .25rem;
  }
  input {
    height: 1.4rem;
    padding: .5rem;
    background-color: variables.$color-secondary;
    color: white;
    border: none;
    font-weight: bolder;
  }
  input::-webkit-calendar-picker-indicator {
    padding: .5rem;
    border-radius: 50%;
    color: variables.$color-secondary;
    background-color: white;
    cursor: pointer;
    font-size: x-large;
  }
}
