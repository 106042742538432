@use '../variables';
@use '../mixins';

.ModalWrapper {
  position: fixed;
  z-index: 3;
  top: 50px;
  max-height: calc(100vh);
  @include mixins.up-to-sm {
    top: 5rem;
  }
  @include mixins.md-up {
    max-height: calc(100vh - 200px);
  }
  width: calc(100% - 1.5rem);
  max-width: 750px;
  margin: 0 auto;
  left: 0;
  right: 0;
  overflow-x: auto;
  @include mixins.md-up {
    overflow-x: unset;
  }
}

.Modal {
  background-color: white;
  padding: 1rem;
  box-shadow: 0 3px 10px #77777780;
  color: variables.$color-secondary;
  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .2);
    z-index: -1;
  }
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-stretch: expanded;
    border-bottom: 1px solid #dddfe1;
    padding-bottom: .25rem;
    .title {
      position: relative;
      top: 1px;
    }
    .closer {
      cursor: pointer;
      display: flex;
    }
  }
  .body {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
