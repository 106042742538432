@use '../variables';
@use '../mixins';

.expanded .menu-wrapper {
  flex-direction: row;
  position: static;
  @include mixins.sm-up {
    width: 12rem;
  }
}

.menu-wrapper {
  display: flex;
  align-items: center;

  @include mixins.sm-up {
    width: 3rem;
  }

  .title {
    display: flex;
    height: 5rem;
    flex-direction: column;
    padding: .25rem;
    transform: scale(0.8);
  }

  .menu-icon {
    position: relative;
    bottom: 2rem;

    svg:nth-of-type(1) {
      top:0;
      position: absolute;
      transform: scale(1.2) rotate(0);
      transition: transform 5s;
    }
    svg:nth-of-type(2) {
      position: absolute;
      top: .5rem;
      transform: scale(1.2) rotate(0);
      transition: transform .5s;
    }
    svg:nth-of-type(3) {
      position: absolute;
      top: -.5rem;
      transform: scale(1.2) rotate(0);
      transition: transform .5s;
    }
  }

  .menu-icon-expanded {
    position: relative;
    bottom: 2rem;
    svg:nth-of-type(1) {
      top:0;
      position: absolute;
      width: 0;
      display: none;
      transition: width .5s;
    }
    svg:nth-of-type(2) {
      position: absolute;
      transform: scale(1.2) rotate(-45deg);
      transition: transform .5s;
    }
    svg:nth-of-type(3) {
      position: absolute;
      transform: scale(1.2) rotate(45deg);
      transition: transform .5s;
    }
  }
  svg {
    margin: .75rem;
    width: 2rem;
    height: 2rem;
    color: variables.$color-text;
  }
}

.MobileMenu {
  position: fixed;
  top: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: fit-content;
  z-index: 999;
  .div {
    flex-direction: row;
  }
  .title {
    flex-direction: column;
    margin-top: .5rem;
    height: max-content;
    justify-content: center;
    transform: scale(1.2);
    margin-left: calc(50vw - 1.5rem);
  }

  .active .nav-icon {
    color: variables.$color-primary;
  }
}

.MainMenu {
  position: static;
  background: white;
  align-items: flex-start;
  flex-direction: column;
  width: max-content;
  min-height: 100vh;
  transition: width .5s;

  .active .nav-icon {
    color: variables.$color-primary;
  }

  .expander {
    padding: 1rem;
    cursor: pointer;
    :hover {
      color: variables.$color-primary;
    }
  }
  .expander:after {
    content: "";
    display: block;
    border-bottom: 1px solid variables.$color-text;
    margin-top: 1rem;
  }
}

.MainMenu.expanded {
  box-shadow: .1rem 0 .5rem grey;
  height: fit-content;

  .nav-group-chevron {
    display: flex;
  }
  .menu-wrapper {
    .title {
      width: 100%;
      height: 5rem;
      display: flex;
      flex-direction: column;
      transform: scale(0.7);
      align-items: center;
      justify-content: flex-start;
      font-size: 2rem;
      padding-top: 0;
      padding-bottom: .5rem;
    }
  }
}
.closer-menu {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 5;
}
