@use "../variables";
@use "../mixins";

.Login {

  background-color: white;
  padding: 1rem;
  font-size: .75rem;
  max-width: calc(400px);
  box-sizing: border-box;

  h3 {
    color: variables.$color-primary;
    text-transform: uppercase;
  }

  p {
    color: variables.$color-secondary;
  }

  a {
    color: variables.$color-primary;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    max-width: 25rem;
  }

}
