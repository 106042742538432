@use "../mixins";
@use "../variables";

.DottedCard {
  border: 1px dashed variables.$color-secondary;
    box-sizing: border-box;
    color: variables.$color-secondary;
  background-color: white;
  & > div {
    margin: .5rem;
  }
@include mixins.sm-up {
    display: flex;
& > div {
      width: 100%;
      display: flex;
      flex-direction: column;
}
}
  div div {
    margin-top: .5rem;
  }
  div div:nth-of-type(-n+3) > span {
    font-size: 0.625rem;
    font-stretch: expanded;
    font-weight: 600;
    text-transform: uppercase;
    margin-left: 1rem;
  }
  div div p {
    margin: 0 0 0 1rem;
  }
}
