@use "../variables";

.Filter {
  text-align: left;
  svg {
    cursor: pointer;
  }
  form {
    .price-wrapper {
      label {
        font-size: .875rem;
        font-weight: 600;
        font-stretch: condensed;
        color: variables.$color-secondary;
        text-transform: uppercase;
      }
    }
    .checkbox > * {
      padding-bottom: 1rem;
    }
    .date {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: .5rem;
      .icon {
        color: variables.$color-secondary;
        display: flex;
        align-items: center;
      }
    }
  }
}
