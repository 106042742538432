@use '../variables';

.DropzoneInput {
  label {
    text-transform: uppercase;
    font-size: .875rem;
    font-weight: 600;
    font-stretch: condensed;
  }
  &.required label:after {
    content: '*';
    color: variables.$color-danger;
    margin-left: .25rem;
  }
  .icon {
    display: flex;
    align-items: center;
  }
  .zone {
    margin-top: 5px;
    border: 2px dashed #cccccc;
    background-color: variables.$color-background;
    display: flex;
    padding: 0 1rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 5rem;
    font-size: .88rem;
    color: #ABAFB3;
    cursor: pointer;
    text-align: center;
    span {
      color: variables.$color-primary;
    }
    & > .info-text {
      font-size: .75rem;
    }
  }
  ul {
    list-style-type: none;
    margin: 1rem 0 0 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    li {
      background-color: variables.$color-background;
      font-size: .88rem;
      border-radius: 3px;
      padding: .25rem .25rem .25rem .5rem;
      display: flex;
      position: relative;
      max-height: 20px;
      White-Space: nowrap;
      span:first-child {
        max-width: 275px;
        Text-overflow: ellipsis;
        overflow: hidden;
      }
      span:last-child {
        display: flex;
        cursor: pointer;
        margin-left: 2rem;
        svg {
          color: variables.$color-danger;
          height: 1rem;
        }
      }
    }
  }
  &.drag-over .zone {
    border: 2px dashed variables.$color-grey;
    background-color: lighten(#7dcbce26, 20%);
  }
  .placeholder-li {
    height: 36.5px;
    display: inline-block;
  }
  &.has-error {
    .zone {
      background-color: lighten(variables.$color-danger, 28%);
    }
  }
}
