@use '../variables';
@use '../mixins';

.nav-group , .nav-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: white;
}
.nav-group-chevron svg {
  display: flex;
  transition: transform .25s;
}

.nav-group-header {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.nav-group-header:hover {
  color: variables.$color-primary;
  span {
    color: variables.$color-primary;
  }
}

.nav-group-header span {
  margin: 0;
  color: variables.$color-text;
  font-size: 1.25rem;
  font-stretch: condensed;
}

.nav-group-body {
  display: flex;
  flex-direction: column;
}

.nav-group-body a {
  text-decoration: none;
  padding: .2rem .5rem;
  color: variables.$color-text;
  font-size: 1.25rem;
  font-stretch: condensed;
  border-left: 3rem solid white;
}

.nav-group-body a:hover {
  color: variables.$color-primary;
}

.nav-group-body a.active {
  color: variables.$color-primary;
}

.nav-item  a {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  color: variables.$color-text;
}

.nav-group-header:hover {
  color: variables.$color-primary;
  .nav-icon {
    color: variables.$color-primary;
  }
}

.nav-icon {
  margin: .5rem 1rem;
}

.nav-item div a {
  display: flex;
  align-items: center;
  color: variables.$color-text;
  text-decoration: none;
  font-size: 1.25rem;
  font-stretch: condensed;
  margin: 0;
}
.nav-item div a.active {
  color: variables.$color-primary;
}

.nav-item:hover, .nav-item:active {
  color: variables.$color-primary;
  .nav-icon {
    color: variables.$color-primary;
  }
  div a {
    color: variables.$color-primary;
  }
}
