@use "../mixins";
@use "../variables";

.Table {
  padding: 1rem;
  overflow-x:auto;
  border-spacing: 0;
  display: grid;
  grid-auto-rows: minmax(max-content, auto);
  align-items: center;
  margin-top: 1rem;

  td, th {
    text-align: left;
    padding: .25rem;
    justify-content: center;
    vertical-align: middle;
  }
  td {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 85%;

    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  th.sortable {
    cursor: pointer;
    & > div {
      display: flex;
      align-items: center;
      width: fit-content;
      svg {
        height: 16px;
      }
    }

  }
  .tags {
    font-size: .875rem;
    text-transform: uppercase;
    color: variables.$color-secondary;
    border: 1px solid variables.$color-secondary;
    background-color: white;
    width: max-content;
    padding: .25rem;
    line-height: 1;
    margin: .25rem;
    &.danger {
      color: variables.$color-danger;
      border-color: variables.$color-danger;
    }
  }
  .TeaserImage {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .shaded, .shaded td {
    background: rgba(0, 0, 0, .05);
  }
  thead, tbody, tr {
    display: contents;
  }
  tr:hover td {
    transition: background-color ease-out 100ms;
    background-color: variables.$color-light-grey;
  }
  .text-center {
    align-items: center;
  }
  .text-right {
    align-items: end;
    padding-right: 1rem;
  }
  .col-span {
    grid-column: 1 / -1;
    width: 100%;
  }
  .Accordion {
    width: 99%;
    height: 1000px;
  }
  .icon-row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    gap: .25rem;
    & > div {
      padding: .25rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    & > div:hover {
      background: #003b5c;
      color: white;
    }
  }
}

.admins-table {
  grid-template-columns:
    minmax(140px, .25fr) minmax(140px, .25fr) minmax(120px, .25fr) minmax(120px, .25fr) 150px;
}
.brands-table {
  grid-template-columns:
    minmax(70px, 120px) minmax(150px, 1fr) 150px;
}
.producttypes-table {
  grid-template-columns:
    minmax(70px, 120px) minmax(70px, 120px) minmax(200px, 2fr) minmax(100px, .3fr) minmax(100px, .25fr)
    minmax(100px, .25fr) 150px;
}
.coupons-table {
  grid-template-columns:
    minmax(150px, .75fr) minmax(200px, 2fr) minmax(100px, .3fr) minmax(80px, .3fr) minmax(120px, .3fr) 150px;
}
.customer-order-table {
  grid-template-columns:
    minmax(150px, 1fr) minmax(180px, 1fr) minmax(100px, 1fr) minmax(140px, 1fr) minmax(120px, 1fr) 3rem;
}
.customer-table {
  grid-template-columns: minmax(150px, 2fr) minmax(150px, 2fr) minmax(140px, 1fr) minmax(140px, 1fr) 7rem 9rem;
}
.sales-table {
  grid-template-columns: minmax(150px, 2fr) minmax(150px, 2fr) minmax(140px, 1fr) minmax(140px, 1fr) 9rem;
}
.field-sales-table {
  grid-template-columns: minmax(150px, 2fr) minmax(150px, 2fr) minmax(140px, 1fr) minmax(140px, 1fr) 9rem;
}
.online-sales-table {
  grid-template-columns: minmax(150px, 2fr) minmax(150px, 2fr) minmax(140px, 1fr) minmax(140px, 1fr) 9rem;
}
.downloads-table {
  grid-template-columns:
    minmax(70px, 100px) minmax(140px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr) minmax(120px, .25fr)
    minmax(120px, .25fr) minmax(120px, .25fr) 150px;
}
.order-table {
  grid-template-columns:
    minmax(150px, 1fr) minmax(150px, 1fr) 180px minmax(140px, 1fr) minmax(140px, 1fr) minmax(120px, 1fr) 3rem;
}
.product-table {
  grid-template-columns:
    minmax(70px, .25fr) minmax(100px, .4fr) minmax(140px, .6fr) minmax(100px, .4fr) minmax(200px, 1.25fr)
    minmax(190px, 1.25fr) minmax(100px, .25fr) 250px 150px;
}
.promotions-has-product-table {
  grid-template-columns:
     150px minmax(70px, .25fr) minmax(100px, .4fr) minmax(140px, .6fr) minmax(100px, .4fr) minmax(200px, 1.25fr);
}
.nr-product-table {
  grid-template-columns:
    minmax(70px, .25fr) minmax(100px, .4fr) minmax(140px, .6fr) minmax(100px, .4fr) minmax(200px, 1.25fr)
    minmax(200px, 1.25fr) 9rem;
}
.promotions-table {
  grid-template-columns: 3rem 5rem minmax(200px, 2fr) minmax(100px, 150px) minmax(120px, 1fr) minmax(100px, 1fr) 150px;
}
.shops-table {
  grid-template-columns: 160px 120px minmax(150px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr) 150px;
}
.wholesalers-table {
  grid-template-columns: minmax(70px, 1fr) 150px;
}
.welcome-panel-table {
  grid-template-columns: 5rem minmax(120px, 1fr) 150px;
}
