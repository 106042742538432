@use "../variables";

.Dropdown {

  user-select: none;
  position: relative;
  display: inline-block;

  .selected-item {
    display: flex;
    align-items: stretch;
    cursor: pointer;
    transition: background-color 250ms;
    background-color: variables.$color-secondary;
    .icon {
      color: white;
      display: flex;
      align-items: center;
      padding-right: .5rem;
    }
    &:hover, &:hover .DropdownItem {
      background-color: darken(variables.$color-secondary, 9%);
      border-color: darken(variables.$color-secondary, 9%);
    }
    svg {
      transition: all 250ms;
    }
  }

  .selection {
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 1;
    transition: height 250ms ease-out;
    overflow: hidden;
    min-width: 100%;
    width: max-content;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        border-top: 1px solid white;
        margin: 0;
      }
    }
  }
  .Label {
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 600;
    font-stretch: condensed;
    color: variables.$color-secondary;
  }
}
