@use '../variables';

.TextInput {

  font-size: .875rem;
  font-weight: 600;
  font-stretch: condensed;
  color: variables.$color-secondary;

  label {
    text-transform: uppercase;
  }

  &.required label:after {
    content: '*';
    color: variables.$color-danger;
    margin-left: .25rem;
  }

  .wrapper {

    min-height: 1.75rem;
    display: flex;
    border: 1px solid variables.$color-secondary;
    padding: .25rem;
    background-color: white;

    input {
      all: unset;
      width: 100%;
      flex: 0 1 100%;
      font-stretch: normal;
      padding: 0 .75rem 0 .5rem;
      &::placeholder {
        opacity: .5;
      }
    }

    .Button {
      width: auto;
      max-height: 1.75rem;
    }

    .icon {
      display: flex;
      align-items: center;
    }

    .clickable {
      cursor: pointer;
    }

  }

  &.has-error .wrapper {
    border-color: variables.$color-danger;
  }

  textarea {
    all: unset;
    white-space: break-spaces;
    width: 100%;
    height: 7.5rem;
  }

}
