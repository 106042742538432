@use '../variables';
@use '../mixins';

.User-Orders {
  .order-table {
    min-width: 100%;
    border-collapse: collapse;
    grid-template-columns:
    minmax(150px, 1fr) minmax(150px, 1fr) 180px minmax(140px, 1fr) minmax(140px, 1fr) minmax(120px, 1fr) 3rem;
    tr:nth-child(even) {
      background: rgba(0, 0, 0, 0.05);
    }
    tr:hover td {
      transition: background-color ease-out 100ms;
      background-color: variables.$color-light-grey;
    }
    .title {
      display: flex;
      align-items: center;
      height: 24px;
    }
    td, th {
      padding-inline: 1rem;
      position: relative;
    }
  }
  .closer {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
  .popup {
    z-index: 2;
    position: absolute;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    box-shadow: .1rem 0 .5rem grey;
    .order {
      display: flex;
      gap: .5rem;
      align-items: center;

    }
    .TextInput {
      margin-top: 1.5rem;
    }
    .selected {
      color: variables.$color-primary;
    }
  }
}
