@use "../mixins";
@use "../variables";

.PromotionsHasProductEdit {
  .ModalWrapper {
    max-width: 1000px;
    .Modal {
      table {
        overflow: scroll;
        max-height: 45vh;
        @media (min-height: 500px) {
          max-height: 60vh;
        }
        @media (min-height: 800px) {
          max-height: 70vh;
        }
      }
    }
  }
}
