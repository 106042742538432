@use '../variables';
@use '../mixins';

.EditShopModal {
  margin-bottom: 1rem;
  margin-top: 1rem;
  overflow-y: auto;
  max-height: 80vh;
  button {
    margin-top: 1rem;
  }
  .span {
    margin-bottom: 1rem;
  }

}
