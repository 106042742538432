@use '../variables';
@use '../mixins';

.Accordion {
  overflow: hidden;
  transition: max-height 200ms ease-out;
  padding: 0 0.25rem;
  .names {
    padding: .5rem;
    background-color: variables.$color-primary;
    color: variables.$color-secondary;
    border: 2px solid variables.$color-secondary;
    overflow-wrap: anywhere;
    hyphens: auto;
    display: flex;
    align-items: center;
  }
  .item {
    padding: .5rem;
    background-color: white;
    border: 2px solid variables.$color-secondary;
    border-top: none;
    overflow-wrap: anywhere;
  }
}
