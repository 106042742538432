@use '../variables';
@use '../mixins';

.promotion-modal {
  max-height: 80vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  Img {
    width: 100%;
    height: auto;
    max-height: 30vh;
    object-fit: contain;
  }
  .modal-box > * {
    padding-bottom: .5rem;
  }
  .linked-products {
    margin: 1rem 0;
    .no-entries {
      display: flex;
      gap: .5rem;
      margin: 1.5rem 0;
    }
  }
}
