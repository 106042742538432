@use '../variables';

.IconButton {
  cursor: pointer;
  padding-inline: .5rem;

  &.primary {
    color: variables.$color-primary;
  }
  &.secondary {
    color: variables.$color-secondary;
  }

  svg {
    height: 1.25rem;
  }

}
