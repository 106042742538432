@use '../variables';
@use '../mixins';

.CustomerPage {
  .POS-edit {
    margin-bottom: 1rem;
  }
  button {
    margin-top: 1rem;
  }
  .CustomerEdit {
    overflow-y: auto;
    max-height: 80vh;
    .add-button {
      margin-bottom: 2rem;
    }
    .pos {
      .address-box {
        position: relative;
        display: flex;
        align-items: center;
        border-radius: 5px;
        border: 1px solid variables.$color-primary;
        padding: .25rem;
        margin-top: .25rem;
        min-height: 2rem;
        & > div {
          p {
            margin: 0;
            text-overflow: ellipsis;
            max-width: 20rem;
            white-space: nowrap;
            overflow: hidden;
            @include mixins.sm-up {
              max-width: 30rem;
            }
            @include mixins.md-up {
              max-width: 40rem;
            }
            @include mixins.lg-up {
              max-width: 12rem;
            }
          }
          &.info {
            @include mixins.lg-up {
              max-width: 10rem;
            }
          }
        }
      }
      .strong {
        font-weight: bold;
      }
      .info-head {
        @include mixins.lg-up {
          max-width: 10rem;
        }
      }
      .edit {
        position: absolute;
        right: 2.25rem;
        padding: .25rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .edit:hover {
        background: variables.$color-secondary;
        color: white;
      }
      .delete {
        position: absolute;
        right: .25rem;
        padding: .25rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .delete:hover {
        background: variables.$color-secondary;
        color: white;
      }
    }
  }
}
