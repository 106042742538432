@use "../variables";
@use "../mixins";

.Screen {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  width: 100%;
  overflow: auto;
  &.horizontal-center {
    align-items: center;
  }
  @include mixins.sm-up {
    h1:first-child {
      margin-top: 0;
    }
  }

  @include mixins.up-to-md {
    padding: 1rem;
    width: calc(100% - 2rem);
  }
  @include mixins.sm-up {
    width: calc(100% - 6rem);
  }
}

.responsive {
  margin-top: 3rem;
}
